<template>
  <div class="content_body" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form :inline="true" size="small">
            <el-form-item label="话术类目">
              <el-input v-model="Name" placeholder="输入话术类目搜索" clearable @clear="handleSearch" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
            <el-form-item label="有效性">
              <el-select v-model="Active" placeholder="请选择有效性" @change="handleSearch" clearable>
                <el-option label="有效" :value="true"></el-option>
                <el-option label="无效" :value="false"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="handleSearch" v-prevent-click>搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button type="primary" @click="showAddDialog" size="small" v-prevent-click>添加类目</el-button>
        </el-col>
      </el-row>
    </div>

    <div class="martp_10">
      <el-table size="small" :data="tableData" row-key="ID" :tree-props="{ children: 'Child' }">
        <el-table-column prop="Name" label="类目名称"></el-table-column>
        <el-table-column label="移动" min-width="180px">
          <template slot-scope="scope">
            <el-button size="small" type="primary" circle icon="el-icon-upload2" :disabled="scope.$index == 0" @click="upOneClick(scope.row, scope.$index)"></el-button>
            <el-button size="small" type="primary" circle :disabled="scope.$index == 0" icon="el-icon-top" @click="upClick(scope.row, scope.$index)"></el-button>
            <el-button size="small" type="primary" circle :disabled="scope.$index == tableDataLength-1" icon="el-icon-bottom" @click="downClick(scope.row, scope.$index)"></el-button>
            <el-button size="small" type="primary" circle :disabled="scope.$index == tableDataLength -1" icon="el-icon-download" @click="downOneClick(scope.row, scope.$index)"></el-button>
          </template>
        </el-table-column>
        <el-table-column prop="Active" label="有效性" :formatter="formatStatus"></el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="showEditDialog(2, scope.row)">编辑</el-button>
            <el-button type="primary" size="small" @click="showEditDialog(1, scope.row)" v-if="scope.row.ParentID == 0">添加子分类</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!--分类弹窗  新增一级分类 -->
    <el-dialog title="话术分类" :visible.sync="dialogVisible" width="30%">
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" size="small" @submit.native.prevent>
        <el-form-item label="话术类目" prop="Name">
          <el-input v-model="ruleForm.Name" autocomplete="off" placeholder="请输入话术分类"></el-input>
        </el-form-item>
        <el-form-item label="是否有效" v-if="isadd=='2'" prop="Active">
          <el-radio-group v-model="ruleForm.Active">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small" v-prevent-click>取 消</el-button>
        <el-button type="primary" @click="addSubmit" :loading="modalLoading" size="small" v-prevent-click>保 存</el-button>
      </div>
    </el-dialog>
    <!--子分类弹窗-->
    <el-dialog :title="dialogTitle" :visible.sync="dialogSubClass" width="30%">
      <div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" size="small" class="demo-ruleForm">
          <el-form-item label="上级类目" prop="ParentID" v-show="ruleForm.ParentID != 0">
            <el-select v-model="ruleForm.ParentID" placeholder="请选择类目" disabled>
              <el-option v-for="(category, index) in productCategory" :key="index" :label="category.Name" :value="category.ID"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="话术类目" prop="Name">
            <el-input clearable v-model="ruleForm.Name"></el-input>
          </el-form-item>
          <el-form-item label="是否有效" v-show="isadd == 2">
            <el-radio-group v-model="ruleForm.Active">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogSubClass = false">取 消</el-button>
        <el-button type="primary" size="small" @click="submitCategoryInfo" :loading="modalLoading">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/CRM/ArtSpeak/artSpeakCategory";

export default {
  name: "ArtSpeakCategory",
  data() {
    return {
      loading: false,
      modalLoading: false,
      dialogSubClass: false,
      Name: "", // 搜索框数据
      Active: true,
      tableData: [],
      dialogVisible: false, //新增弹窗
      isadd: "1",
      ruleForm: {
        Name: "",
        Active: "",
      },
      //新增
      rules: {
        Name: [
          {
            required: true,
            message: "请输入话术分类",
            trigger: ["blur", "change"],
          },
        ],

        Active: [
          { required: true, message: "请选择是否有效", trigger: "change" },
        ],
      },
      // 编辑信息 所传ID
      updateId: "",
      dialogTitle: "",
      productCategory: [], // 话术一级分类
      moveIndex: "",
      moveID: "",
    };
  },

  computed: {
    tableDataLength() {
      var tableData = this.tableData;
      var length = 0;
      if (tableData && tableData.length > 0) {
        tableData.forEach((item, index) => {
          if (item.Child && index != tableData.length - 1) {
            length += item.Child.length;
          }
          length += 1;
        });
      }
      return length;
    },
  },
  methods: {
    //状态显示转换
    formatStatus: function (row) {
      return row.Active ? "是" : "否";
    },
    // 数据显示
    handleSearch: function () {
      var that = this;
      that.getProductCategory();
    },
    // 获取话术分类列表
    getProductCategory: function () {
      var that = this;
      that.loading = true;
      var params = {
        Name: that.Name,
        Active: that.Active,
      };
      API.getProductCategory(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.dialogVisible = false;
            that.tableData = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 一级话术分类
    getProductCategoryTop: function () {
      let that = this;
      that.loading = true;

      API.getProductCategoryTop()
        .then((res) => {
          if (res.StateCode == 200) {
            that.productCategory = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    /**  提交分类信息  */
    submitCategoryInfo() {
      let that = this;
      that.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (that.isadd == 1) {
            // 新增子分类
            that.createProductCategory();
          } else {
            // 编辑一级分类
            that.updateProductCategory();
          }
        }
      });
    },
    // 创建话术类别
    createProductCategory: function () {
      var that = this;
      that.loading = true;
      var params = {
        ParentID: that.ruleForm.ParentID,
        Active: that.ruleForm.Active,
        Name: that.ruleForm.Name,
      };
      API.createProductCategory(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogVisible = false;
            that.dialogSubClass = false;
            that.getProductCategory();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 编辑更新话术类别
    updateProductCategory: function () {
      var that = this;
      that.loading = true;
      var params = {
        Name: that.ruleForm.Name,
        ID: that.ruleForm.ID,
        Active: that.ruleForm.Active,
        ParentID: that.ruleForm.ParentID,
      };
      API.updateProductCategory(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogSubClass = false;
            that.getProductCategory();
            that.getProductCategoryTop();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 新增
    showAddDialog: function () {
      var that = this;
      that.ruleForm = {
        Name: "",
        ParentID: 0,
      };
      that.isadd = 1;
      that.dialogVisible = true;
    },

    // 编辑
    showEditDialog: function (index, row) {
      var that = this;
      that.isadd = index;
      // 添加子分类
      if (index == 1) {
        that.getProductCategoryTop();
        that.ruleForm = {
          Name: "",
          ParentID: row.ID,
          ParentName: row.Name,
          Active: "",
        };
        that.dialogTitle = "新增子分类";
      } else {
        that.dialogTitle = "编辑分类";
        that.ruleForm = Object.assign({}, row);
      }
      that.dialogSubClass = true;
    },

    //模态窗数据  一级分类提交
    addSubmit: function () {
      let that = this;
      that.$refs.ruleForm.validate((valid) => {
        if (valid) {
          that.createProductCategory();
        }
      });
    },
    // 向上移动
    upClick(row, index) {
      var that = this;
      that.moveIndex = 3;
      that.moveID = row.ID;
      that.setRecursion(that.tableData);
    },
    // 向下移动
    downClick(row, index) {
      var that = this;
      that.moveIndex = 4;
      that.moveID = row.ID;
      that.setRecursion(that.tableData);
    },
    // 移动至第一个
    upOneClick(row) {
      var that = this;
      that.moveIndex = 1;
      that.moveID = row.ID;
      that.setRecursion(that.tableData);
    },
    // 移动至最后一个
    downOneClick(row, index) {
      var that = this;
      that.moveIndex = 2;
      that.moveID = row.ID;
      that.setRecursion(that.tableData);
    },

    // 递归
    setRecursion(data) {
      var that = this;
      var index = data.length;
      var moveId = "",
        beforeId = "",
        destParentID = 0;
      for (let i = 0; i < index; i++) {
        if (data[i].ID == that.moveID) {
          if (that.moveIndex == 1) {
            moveId = data[i].ID;
            beforeId = "";
            destParentID = data[i].ParentID;
          } else if (that.moveIndex == 2) {
            moveId = data[i].ID;
            beforeId = data[index - 1].ID;
            destParentID = data[i].ParentID;
          } else if (that.moveIndex == 3) {
            moveId = data[i].ID;
            destParentID = data[i].ParentID;
            if (i == 0 || i == 1) {
              beforeId = "";
            } else {
              beforeId = data[i - 2].ID;
            }
          } else {
            // debugger
            moveId = data[i].ID;
            destParentID = data[i].ParentID;
            if (i == index - 1) {
              beforeId = data[i == 0 ? 0 : i - 1].ID;
            } else {
              beforeId = data[i + 1].ID;
            }
          }
          that.moveProductCategory(moveId, beforeId, destParentID);
          return false;
        }
        if (data[i].Child) {
          that.setRecursion(data[i].Child);
        }
      }
    },
    // 调整话术类别顺序
    moveProductCategory: function (moveId, beforeId, DestParentID) {
      var that = this;
      that.loading = true;
      var params = {
        MoveID: moveId,
        BeforeID: beforeId,
        DestParentID: DestParentID,
      };
      API.moveProductCategory(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.getProductCategory();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
  mounted() {
    var that = this;
    that.getProductCategory();
    that.getProductCategoryTop();
  },
};
</script>

<style scoped lang="scss">
</style>

