
 import * as API from '@/api/index'

 export default {
     // 获取产品分类列表 一级
     getProductCategoryTop: params => {
         return API.POST('api/artSpeakCategory/top', params)
     },
     // 获取产品分类列表
     getProductCategory: params => {
         return API.POST('api/artSpeakCategory/all', params)
     },
     // 创建产品类别
     createProductCategory: params => {
         return API.POST('api/artSpeakCategory/create', params)
     },
     // 编辑更新产品类别
     updateProductCategory: params => {
         return API.POST('api/artSpeakCategory/update', params)
     },
     // 调整产品类别顺序
     moveProductCategory: params => {
         return API.POST('api/artSpeakCategory/move',params)
     },
     // 获取产品分类列表(可以，去除没有二级的类别)
     getValidProductCategory() {
         return API.POST('api/artSpeakCategory/valid')
     },
 }
 